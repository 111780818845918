/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    usersData: DEFAULT_DATA,
    userTypes: [],
  },
  mutations: {
    setUsersData(state, payload) {
      state.usersData = payload;
    },
    setUserTypes(state, payload) {
      state.userTypes = payload;
    },
    setDefaultUsersData(state) {
      state.usersData = DEFAULT_DATA;
      state.userTypes = [];
    },
  },
  actions: {
    async getUsersData({ commit }, payload) {
      try {
        commit("setLoader", true);

        const usersData = (await api.users.getUsersData(payload)).data;

        commit("setUsersData", usersData);
        commit("setLoader", false);
      } catch (e) {
        throw e;
      }
    },
    async createUserData({ dispatch }, payload) {
      try {
        await api.users.createUser(payload);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    async updateUserData({ dispatch }, payload) {
      try {
        await api.users.updateUser(payload.id, payload.user_data);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    async removeUserData({ dispatch }, payload) {
      try {
        await api.users.removeUser(payload);

        dispatch("getUsersData", {});
      } catch (e) {
        throw e;
      }
    },
    async getUserTypes({ commit }) {
      try {
        const types = (await api.users.getUserTypes()).data;

        commit("setUserTypes", types);
      } catch (e) {
        throw e;
      }
    },
    clearUsersData({ commit }) {
      commit("setDefaultUsersData");
    },
  },
  getters: {},
};
