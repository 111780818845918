import { createStore } from "vuex";

import admin_auth from "./administration/auth";
import posts from "./administration/posts";
import users from "./administration/users";
import sections from "./administration/sections";

import public_posts from "./public/posts";
import public_sections from "./public/sections";
import public_search from "./public/searching";
import public_regions from "./public/regions";

export default createStore({
  state: {
    loader: true,
  },
  getters: {},
  mutations: {
    setLoader(state, status) {
      state.loader = status;
    },
  },
  actions: {},
  modules: {
    admin_auth,
    posts,
    users,
    sections,
    public_posts,
    public_sections,
    public_search,
    public_regions,
  },
});
