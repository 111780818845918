<template>
  <aside class="site-sidebar">
    <div class="important-links-list">
      <div class="grid justify-content-center">
        <div
          class="col-12 md:col-6 lg:col-12"
          v-for="link in links"
          :key="link.id"
        >
          <ImportantLinkCard :link="link" />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import ImportantLinkCard from "../content/ImportantLinkCard.vue";

export default {
  components: {
    ImportantLinkCard,
  },
  props: {
    links: Array,
  },
};
</script>
