<template>
  <TableSceleton v-if="loader" />
  <DataTable
    v-else
    stripedRows
    :filterDisplay="$route.name === ADMIN_PAGES_ROUTE ? 'row' : ''"
    class="p-datatable-sm"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Показано {first} з {last} із {totalRecords}"
    :rowHover="true"
    :value="posts"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <span class="text-xl text-900 font-bold">{{ title }}</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          v-tooltip.left="'Додати нову публікацію'"
          @click="
            $router.push({
              name: routerList[$route.name].createRoute,
            })
          "
        />
      </div>
    </template>
    <Column header="Назва" headerStyle="width: 20%">
      <template #body="slotProps">
        <div
          class="cursor-pointer"
          @click="
            $router.push({
              name: routerList[$route.name].editRoute,
              params: { id: slotProps.data.id },
            })
          "
        >
          <span>{{ slotProps.data.title }}</span>
        </div>
      </template>
    </Column>
    <Column
      v-if="$route.name === ADMIN_PAGES_ROUTE"
      header="Розділ"
      headerStyle="width: 20%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          editable
          :placeholder="'Усі'"
          :filterItems="pagesList"
          :labelOption="'name'"
          :filtersName="'post_filters'"
          :key="filterKey"
          :filterField="filterPostsBySectionsID.field"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterPostsBySectionsID, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterPostsBySectionsID, null);
            }
          "
        />
      </template>
      <template #body="slotProps">
        <div
          class="cursor-pointer"
          @click="
            $router.push({
              name: routerList[$route.name].editRoute,
              params: { id: slotProps.data.id },
            })
          "
        >
          <span>{{ sectionByID(slotProps.data.section.id)?.name }}</span>
        </div>
      </template>
    </Column>
    <Column header="Дата" headerStyle="width: 15%">
      <template #body="slotProps">
        <div
          class="cursor-pointer"
          @click="
            $router.push({
              name: routerList[$route.name].editRoute,
              params: { id: slotProps.data.id },
            })
          "
        >
          <span>{{ slotProps.data.updated_at }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="is_active"
      headerStyle="width: 10%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :placeholder="'Усі'"
          :filterItems="postStatuses"
          :labelOption="'name'"
          :filtersName="'post_filters'"
          :key="filterKey"
          :filterField="filterPostByStatus.field"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterPostByStatus, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterPostByStatus, null);
            }
          "
        />
      </template>
      <template #header>
        <div class="text-center w-full">
          <span>Статус</span>
        </div>
      </template>
      <template #body="slotProps">
        <div class="text-center">
          <Tag
            :severity="!!slotProps.data.is_published ? 'success' : 'warning'"
            :value="!!slotProps.data.is_published ? 'Опубліковано' : 'Чернетка'"
          />
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 15%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :showOptions="false"
          @clearFilters="
            () => {
              filterKey++;

              $emit('clearFilters');
            }
          "
        />
      </template>
      <template #body="slotProps">
        <TableItemControls
          :itemName="slotProps.data.title"
          @editFunc="
            $router.push({
              name: routerList[$route.name].editRoute,
              params: { id: slotProps.data.id },
            })
          "
          @removeFunc="removePost(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>

  <DynamicDialog />
</template>

<script>
import TableItemControls from "../ui/TableItemControls.vue";
import ColumnFilterPanel from "../ui/ColumnFilterPanel.vue";
import DynamicDialog from "primevue/dynamicdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Tag from "primevue/tag";
import TableSceleton from "../ui/TableSceleton.vue";

import filters from "@/config/filters.yml";

import DialogMixin from "@/mixins/dialog";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    TableItemControls,
    DataTable,
    Column,
    Button,
    DynamicDialog,
    Tag,
    ColumnFilterPanel,
    TableSceleton,
  },
  mixins: [DialogMixin],
  props: {
    posts: Array,
    title: String,
    filters: Object,
    routerList: Object,
  },
  emits: ["getItemsByFilter", "clearFilters"],
  data() {
    return {
      ADMIN_PAGES_ROUTE: "admin-pages",
      filterPostsBySectionsID: {
        field: filters.posts.filterBySectionID,
        value: "",
      },
      filterPostByStatus: {
        field: filters.posts.filterByPublished,
        value: "",
      },
      postStatuses: filters.posts.statuses,
      filterKey: 0,
    };
  },
  computed: {
    ...mapState({
      loader: (state) => state.loader,
      postTypes: (state) => state.posts.postTypes,
    }),
    ...mapGetters(["pagesList", "sectionByID", "isAdmin"]),
  },
  methods: {
    ...mapActions(["removePostData", "getPostsData", "getPostTypesData"]),

    getItemsByFilters(filter, value) {
      filter.value = value;

      this.$emit("getItemsByFilter", filter);
    },
    async removePost(id) {
      await this.removePostData({
        id: id,
        filters: this.filters,
      });
    },
  },
};
</script>
