<template>
  <div class="content p-0">
    <Breadcrumb :home="home" :model="breadcrumbs" />
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: {
    breadcrumbs: Array,
  },
  data() {
    return {
      home: {
        icon: "pi pi-home",
        to: {
          name: "admin-news",
        },
      },
    };
  },
};
</script>
