<template>
  <PageHeader />

  <main class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          Новини
        </h1>
        <hr class="content-header-divider" />
      </div>
      <div class="grid">
        <div
          class="col-12 md:col-6 lg:col-4"
          v-for="news in newsData.rows"
          :key="news.id"
        >
          <NewsCard :news="news" />
        </div>
      </div>
    </div>
    <PagePaginator
      v-if="newsData.rows"
      :count="newsData?.count"
      :page="newsData?.pages"
      :totalRows="newsData?.total_rows"
      :pageSize="filters.pageSize"
      @getItemsByPage="async (page) => await getNewsByPage(page)"
    />
  </main>

  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PagePaginator from "@/components/public/page/PagePaginator.vue";
import NewsCard from "@/components/public/content/NewsCard.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

import { mapActions, mapState } from "vuex";

export default {
  name: "AllNewsView",
  components: {
    PageHeader,
    PageFooter,
    PagePaginator,
    NewsCard,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: types.post.news,
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortNewsFilter: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filters: {
        page: 1,
        pageSize: 12,
      },
    };
  },
  computed: {
    ...mapState({
      newsData: (state) => state.public_posts.newsData,
    }),
  },
  methods: {
    ...mapActions(["getPublicPostsData", "clearPublicPostsData"]),

    async getNewsByPage(page) {
      this.filters.page = page;

      await this.getNews();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getNews() {
      this.filters = this.createFilters(this.filters, [
        this.filterPostsByType,
        this.filterPostsIsPubliched,
        this.sortNewsFilter,
      ]);

      try {
        await this.getPublicPostsData({
          filters: this.filters,
          postType: types.post.news,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      await this.getNews();
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
