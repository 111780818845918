import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/public/HomeView.vue";
import SingleView from "../views/public/SingleView.vue";
import AllNewsView from "../views/public/AllNewsView.vue";
import AllImportantLinksView from "../views/public/AllImportantLinksView.vue";
import FeedbackView from "../views/public/FeedbackView.vue";
import SearchResultView from "../views/public/SearchResultView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";
import NewsView from "../views/public/NewsView.vue";

import middleware from "./middleware";
import adminRoutes from "./admin";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "news",
    component: AllNewsView,
  },
  {
    path: "/news/:page",
    name: "news-item",
    component: NewsView,
  },
  {
    path: "/common/:page",
    name: "single",
    component: SingleView,
  },
  {
    path: "/:section/:page*",
    name: "single",
    component: SingleView,
  },
  {
    path: "/feedback",
    name: "feedback",
    component: FeedbackView,
  },
  {
    path: "/links",
    name: "links",
    component: AllImportantLinksView,
  },
  {
    path: "/search",
    name: "search",
    component: SearchResultView,
  },
  {
    path: "/backend",
    meta: { isAdmin: true },
    children: adminRoutes,
  },
  { path: "/:pathMatch(.*)*", name: "404-page", component: PageNotFoundView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => middleware.getAccessPermission(to, next));

export default router;
