<template>
  <div class="header-info-panel">
    <NavbarVisualImpairment />

    <div
      class="header-contact-info"
      v-html="siteContacts(CONTACTS_NAVBAR)?.text"
    ></div>
    <a class="fb-link" target="_blank" :href="FACEBOOK_URL">
      <i class="fb-icon"> </i>
      Офіційна сторінка в Facebook
    </a>
  </div>
</template>

<script>
import NavbarVisualImpairment from "./NavbarVisualImpairment.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    NavbarVisualImpairment,
  },
  props: {
    contacts: Object,
  },
  data() {
    return {
      CONTACTS_NAVBAR: "contacts-navbar",
      FACEBOOK_URL: "https://www.facebook.com/profile.php?id=61552803666882",
    };
  },
  computed: {
    ...mapState({
      fontSetting: (state) => state.app.fontSetting,
    }),
    ...mapGetters(["siteContacts"]),
  },
};
</script>
