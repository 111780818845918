import store from "@/store";

export default {
  getAccessPermission(to, next) {
    if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
      if (store.getters.adminTokenIsValid) {
        next();
      } else {
        store.dispatch("signOutAdmin");

        next({ path: "/" });
      }
    } else {
      next();
    }
  },
};
