<template>
  <PageHeader />
  <PageBreadcrumbs :items="breadcrumbs.items" />
  <article class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          {{ singleNews?.title }}
        </h1>
        <hr class="content-header-divider" />
      </div>

      <div class="single-page-content-wrapper">
        <Image
          v-if="singleNews?.img"
          class="single-page-main-img"
          preview
          :src="siteURL + singleNews?.img"
        />

        <div
          v-if="singleNews?.short_text"
          class="single-page-shorttext"
          v-html="singleNews?.short_text"
        ></div>
        <div
          v-html="
            singleNews?.text && $route.query.search
              ? showSearchedText(singleNews?.text)
              : singleNews?.text
          "
        ></div>
      </div>
    </div>
  </article>
  <div class="p-container">
    <div class="news-controls relative">
      <a
        v-if="singleNews?.url_prev"
        class="previous-news absolute left-0"
        :href="singleNews?.url_prev"
      >
        <i></i>
        <span>Попередня <span class="hidden md:inline">новина</span></span>
      </a>
      <a
        v-if="singleNews?.url_next"
        class="next-news absolute right-0"
        :href="singleNews?.url_next"
      >
        <span>Наступна <span class="hidden md:inline">новина</span></span>
        <i></i>
      </a>
    </div>
  </div>
  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PageBreadcrumbs from "@/components/public/page/PageBreadcrumbs.vue";
import Image from "primevue/image";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";
import StylesMixin from "@/mixins/styles";
import SearchMixin from "@/mixins/search";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleView",
  components: {
    PageHeader,
    PageFooter,
    Image,
    PageBreadcrumbs,
  },
  mixins: [FiltersMixin, StylesMixin, SearchMixin],
  data() {
    return {
      breadcrumbs: {
        items: [
          {
            label: "Новини",
            to: "/news",
          },
        ],
      },
      siteURL: process.env.VUE_APP_API_URL,

      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterPostsByURL: {
        field: filters.posts.filterByURL,
        value: null,
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["single", "importantLinks"]),

    singleNews() {
      return this.single(types.post.news);
    },
    importantLinksList() {
      return this.importantLinks(5);
    },
  },
  methods: {
    ...mapActions(["getPublicPostsData", "clearPublicPostsData"]),
  },
  async mounted() {
    this.filterPostsByURL.value = this.$route.params.page;
    this.filters = this.createFilters({}, [
      this.filterPostsByType,
      this.filterPostsByURL,
      this.filterPostsIsPubliched,
    ]);

    try {
      this.filterPostsByType.value = types.post.page;

      await this.getPublicPostsData({
        filters: this.filters,
        postType: types.post.news,
      });
    } catch (e) {
      console.log(e);
    }

    this.breadcrumbs.items.push({
      label: this.singleNews.title,
    });
  },
  updated() {
    if (!this.singleNews) {
      this.$router.push({ name: "404-page" });
    }
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
