<template>
  <div class="public-confirm-dialog-content text-center">
    <img
      v-if="data.message.isSuccessful"
      src="../../../assets/img/content/check-circle-fill.svg"
      alt=""
      srcset=""
    />
    <h1 class="public-confirm-dialog-header">{{ data.message.header }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>
