<template>
  <Paginator
    class="p-0"
    :rows="20"
    :totalRecords="totalRows"
    :rowsPerPageOptions="[20, 40, 80]"
    @page="(event) => $emit('getItemsByPage', event)"
  />
</template>

<script>
import Paginator from "primevue/paginator";
export default {
  components: {
    Paginator,
  },
  props: {
    rows: Number,
    totalRows: Number,
  },
  emits: ["getItemsByPage"],
};
</script>
