<template>
  <button class="footer-sitemap-btn" @click="showMap = !showMap">
    <span>Мапа сайту</span><i :class="[showMap ? 'show' : '']"></i>
  </button>
  <div :class="['footer-sitemap', showMap ? 'show' : '']">
    <hr class="footer-divider" />

    <div class="grid">
      <div
        v-for="section in sections"
        class="col-12 md:col-4"
        :key="section.id"
      >
        <h1 v-if="section.sub_sections" class="footer-site-map-item">
          {{ section.name }}
        </h1>
        <a v-else class="footer-site-map-item" :href="section.url">{{
          section.name
        }}</a>
        <ul v-if="section.sub_sections" class="footer-sitemap-list">
          <li
            v-for="subsection in section.sub_sections"
            class="footer-sitemap-list-item"
            :key="subsection.id"
          >
            <a
              class="footer-sitemap-list-item-url"
              :href="
                subsection.url_is_external
                  ? subsection.url
                  : `${section.url}/${subsection.url}`
              "
            >
              {{ subsection.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showMap: false,
    };
  },
  computed: {
    ...mapState({
      sections: (state) => state.public_sections.sections,
    }),
  },
};
</script>
