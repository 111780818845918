/* eslint-disable no-useless-catch */
import api from "@/api/public/index";
import types from "@/config/types.yml";

export default {
  state: {
    searchData: [],
  },
  mutations: {
    setSearchResults(state, payload) {
      state.searchData = payload;
    },
    setSearchDataDefault(state) {
      state.searchData = [];
    },
  },
  actions: {
    async getItemsBySearchQuery({ commit }, payload) {
      try {
        const searchData = (
          await api.search.getItemsBySearchQuery({
            value: payload,
          })
        ).data.data;

        commit("setSearchResults", searchData);
      } catch (e) {
        throw e;
      }
    },
    clearSearchData({ commit }) {
      commit("setSearchDataDefault");
    },
  },
  getters: {
    searchingResult: (state) => {
      if (Array.isArray(state.searchData)) {
        return state.searchData.filter(
          (el) => el.id_post_type !== types.post.contact
        );
      }

      return state.searchData;
    },
  },
};
