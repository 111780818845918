import helpers from "@/helpers";

export default (instance) => {
  return {
    getPostsDataByType(payload) {
      return instance
        .post(`api/posts/all`, payload)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
    getPostTypesData() {
      return instance
        .get(`api/post_types`)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
  };
};
