<template>
  <div class="latest-news-card">
    <a class="" :href="`news/${news?.url}`">
      <div class="latest-news-img-wrapper">
        <img
          v-if="news?.img"
          class="latest-news-card-img"
          :src="siteURL + news?.img"
        />
        <img
          v-else
          class="latest-news-card-img"
          src="@/assets/img/content/news_item.png"
        />
      </div>
      <div class="latest-news-card-content">
        <p class="news-card-date">{{ news?.updated_at }}</p>
        <p class="news-card-text" v-html="news?.title"></p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    news: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
