<template>
  <nav class="header-navbar">
    <div class="p-container h-full">
      <div class="h-full flex flex-column lg:flex-none justify-content-between">
        <div>
          <NavbarSearchForm
            class="lg:hidden"
            @closeMobileNavBar="$emit('toggleMobileMenu')"
          />

          <ul
            class="lg:flex justify-content-between align-items-center navbar-menu"
          >
            <NavbarMenuItem
              v-for="menuItem in sections"
              v-model:showSubmenuList="showSubmenuList"
              :key="menuItem.id"
              :menuItem="menuItem"
            />
            <li
              class="search-menu-item navbar-menu-item hidden lg:inline"
              @click="(showSearchForm = !showSearchForm), searchFormListener()"
            >
              <i class="search-menu-icon"></i>
              <span class="navbar-menu-item-title">Пошук</span>
            </li>
          </ul>
        </div>

        <div class="lg:hidden">
          <hr class="mobile-navbar-divider" />
          <NavbarContacList class="mt-3" />
        </div>

        <NavbarSearchForm
          v-if="showSearchForm"
          class="hidden lg:block"
          v-model:showSearchForm="showSearchForm"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarMenuItem from "./navbar/NavbarMenuItem.vue";
import NavbarContacList from "./navbar/NavbarContacList.vue";
import NavbarSearchForm from "./navbar/NavbarSearchForm.vue";
import { mapActions, mapState } from "vuex";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

export default {
  components: {
    NavbarMenuItem,
    NavbarContacList,
    NavbarSearchForm,
  },
  mixins: [FiltersMixin],
  emits: ["toggleMobileMenu"],
  data() {
    return {
      showSubmenuList: null,
      showSearchForm: false,
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filters: {},
      page: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState({
      sections: (state) => state.public_sections.sections,
    }),
  },
  watch: {
    showSubmenuList(newValue) {
      if (newValue) {
        this.submenuListener();
      }
    },
  },
  methods: {
    ...mapActions(["getPublicSectionsData", "getPublicPostsData"]),

    submenuListener() {
      document.addEventListener("click", (ev) => {
        if (
          !ev.target.parentNode?.classList?.value
            .split(" ")
            .some(
              (el) =>
                el === "navbar-submenu-wrapper" ||
                el === "navbar-menu" ||
                el === "navbar-menu-item"
            )
        ) {
          this.showSubmenuList = null;
        }
      });
    },
    searchFormListener() {
      document.addEventListener("click", (ev) => {
        if (
          !ev.target.parentNode?.classList?.value
            .split(" ")
            .some(
              (el) =>
                el === "search-form-wrapper" ||
                el === "search-form-field" ||
                el === "search-form" ||
                el === "navbar-menu-item" ||
                el === "search-form-btn" ||
                el === "w-full"
            )
        ) {
          this.showSearchForm = false;
        }
      });
    },
  },
  async mounted() {
    this.filterPostsByType.value = types.post.contact;
    this.filters = this.createFilters({}, [this.filterPostsByType]);

    try {
      await this.getPublicSectionsData();
      await this.getPublicPostsData({
        filters: this.filters,
        postType: types.post.contact,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
