<template>
  <div class="flex">
    <div class="align-self-center" style="flex: 1">
      <Skeleton width="100%" class="mb-2"></Skeleton>
      <Skeleton width="100%" class="mb-2"></Skeleton>
      <Skeleton width="100%" class="mb-2" height="4rem"></Skeleton>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  components: {
    Skeleton,
  },
};
</script>
