<template>
  <div class="content-paginator">
    <Paginator
      :rows="12"
      :pageLinkSize="4"
      :totalRecords="totalRows"
      :template="{
        default:
          'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
      }"
      @page="
        (e) => {
          currentPage = e.page + 1;
          if (currentPage !== page) {
            $emit('getItemsByPage', currentPage);
          }
        }
      "
    />
  </div>
</template>

<script>
import Paginator from "primevue/paginator";

export default {
  components: {
    Paginator,
  },
  props: {
    page: Number,
    count: Number,
    totalRows: Number,
    pageSize: Number,
  },
  emits: ["getItemsByPage"],
  methods: {
    test(e) {
      console.log(e);
    },
  },
  data() {
    return {
      lastPage: Math.ceil(this.totalRows / this.pageSize),
    };
  },
};
</script>
