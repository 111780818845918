/* eslint-disable no-useless-catch */
import api from "@/api/public/index";

export default {
  state: {
    sections: [],
  },
  mutations: {
    setPublicSectionsData(state, payload) {
      state.sections = payload;
    },
    setDefaultPublicSectionsData(state) {
      state.sections = [];
    },
  },
  actions: {
    async getPublicSectionsData({ commit }) {
      try {
        const sections = (await api.sections.getSections()).data.data;

        commit("setPublicSectionsData", sections);
      } catch (e) {
        throw e;
      }
    },
  },
};
