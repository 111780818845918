import jwt_decode from "jwt-decode";

export default {
  getRelevanceToken(token) {
    if (token) {
      return jwt_decode(token).exp * 1000 > Date.now();
    }

    return false;
  },
};
