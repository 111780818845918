export default {
  methods: {
    getBgColor(bgColor) {
      return bgColor && bgColor !== "" ? `background-color: ${bgColor};` : "";
    },
    getColor(textColor) {
      return textColor && textColor !== "" ? `color: ${textColor};` : "";
    },
    getBgImg(siteUrl, bgIMG) {
      return bgIMG && bgIMG !== ""
        ? `background-image: url(${siteUrl + bgIMG});`
        : "";
    },
  },
};
