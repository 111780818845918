<template>
  <header class="header">
    <div class="header-site-info">
      <div class="p-container">
        <div class="flex justify-content-between align-items-center">
          <NavbarMenuBtn
            class="lg:hidden"
            :key="toggleBtnKey"
            @click="toggleMobileMenu"
          />
          <router-link to="/">
            <div
              class="header-site-logo flex align-items-center lg:w-10 xl:w-auto"
            >
              <img
                class="site-logo-img"
                src="@/assets/img/content/site_logo.png"
              />
              <h1 class="site-logo-text inline">
                Черкаський обласний контактний центр
              </h1>
            </div>
          </router-link>
          <NavbarContacList class="hidden lg:block lg:w-5 xl:w-auto" />
        </div>
      </div>
    </div>
    <PageNavBar
      :class="['lg:block', showMobileMenu ? '' : 'hidden']"
      @toggleMobileMenu="toggleMobileMenu"
    />
  </header>
</template>

<script>
import PageNavBar from "./PageNavBar.vue";
import NavbarContacList from "./navbar/NavbarContacList.vue";
import NavbarMenuBtn from "./navbar/NavbarMenuBtn.vue";

export default {
  components: {
    PageNavBar,
    NavbarContacList,
    NavbarMenuBtn,
  },
  data() {
    return {
      showMobileMenu: false,
      toggleBtnKey: 0,
    };
  },
  methods: {
    toggleMobileMenu() {
      let body = document.getElementsByTagName("body")[0];
      if (this.showMobileMenu) {
        body.style.overflow = "scroll";
        this.toggleBtnKey++;
      } else {
        body.style.overflow = "hidden";
      }

      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>
