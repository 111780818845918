import helpers from "@/helpers";

export default (adminURL, instance) => {
  return {
    removeIMG(payload) {
      return instance
        .delete(
          adminURL + "delimg",
          { data: payload },
          {
            headers: {
              "Content-type": "multipart/form-data",
            },
          }
        )
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
  };
};
