<template>
  <div>
    <div class="text-center">
      <h4 class="mt-0">{{ title }}</h4>
      <Image
        v-if="imgSrc || imgSrcLocal"
        width="350"
        preview
        :src="imgSrcLocal ? imgSrcLocal : imgSrc ? siteURL + imgSrc : ''"
      />
    </div>
    <div class="flex justify-content-center gap-2 mt-2">
      <div class="file file--upload">
        <label :for="id"> Прикріпити зображення </label>
        <input :id="id" type="file" @change="(e) => uploadIMG(e)" />
      </div>
      <Button
        :disabled="!imgSrc && !imgSrcLocal"
        label="Видалити зображення"
        size="small"
        @click="removeIMG(imgSrc)"
      />
    </div>
  </div>
</template>

<script>
import Image from "primevue/image";
import Button from "primevue/button";
import api from "@/api/administration/index";

export default {
  components: {
    Image,
    Button,
  },
  props: {
    img: [File, String],
    title: String,
    id: String,
    imgSrc: String,
  },
  emits: ["update:img", "update:imgSrc", "remove"],
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
      imgSrcLocal: null,
    };
  },
  methods: {
    uploadIMG(e) {
      if (!e.target.files.length) return;

      let reader = new FileReader();

      let file = e.target.files[0];

      reader.addEventListener(
        "load",
        function () {
          this.imgSrcLocal = reader.result;
        }.bind(this),
        false
      );

      if (file) {
        if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
          reader.readAsDataURL(file);
        }
      }

      this.$emit("update:img", file);
    },

    async removeIMG(img) {
      if (img) {
        let formData = new FormData();
        formData.append("path", img);
        try {
          await api.img.removeIMG(formData);

          this.$emit("update:imgSrc", null);
        } catch (e) {
          console.log(e);
        }
      } else {
        this.imgSrcLocal = null;
      }
    },
  },
};
</script>
