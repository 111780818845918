<template>
  <Dropdown
    editable
    showClear
    appendTo="body"
    id="region"
    optionLabel="name"
    optionValue="name"
    v-model="region"
    emptyFilterMessage="Нічого не знайдено"
    emptyMessage="Нічого не знайдено"
    emptySelectionMessage="Немає вибраного елемента"
    :class="['feedback-form-input input-dropdown w-full']"
    :options="regions"
    @change="(ev) => createSearchingField(ev)"
  />
  <label for="region" class="input-field-label">
    {{ `Область/регіон${selectedRegion ? "" : " (обов'язково)"}` }}
  </label>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    selectedRegion: String,
    regionsList: Array,
  },
  emits: ["update:selectedRegion"],
  data() {
    return {
      search: null,
      region: null,
    };
  },
  computed: {
    regions() {
      if (this.search && isNaN(this.search)) {
        return this.regionsList.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
      return this.regionsList;
    },
  },
  watch: {
    region(newID) {
      this.$emit("update:selectedRegion", newID);
      this.search = null;
    },
  },
  methods: {
    createSearchingField(ev) {
      if (Object.getPrototypeOf(ev.originalEvent) === InputEvent.prototype) {
        this.search = ev.value;
      }
    },
  },
};
</script>
