/* eslint-disable no-useless-catch */
import api from "@/api/public/index";

export default {
  state: {
    regions: [],
  },
  mutations: {
    setPublicRegionsData(state, payload) {
      state.regions = payload;
    },
    setDefaultPublicRegionsData(state) {
      state.regions = [];
    },
  },
  actions: {
    async getPublicRegionsData({ commit }) {
      try {
        const regions = (await api.regions.getRegions()).data.data;

        commit("setPublicRegionsData", regions);
      } catch (e) {
        throw e;
      }
    },
    clearPublicRegionsData({ commit }) {
      commit("setDefaultPublicRegionsData");
    },
  },
};
