import instance from "../instance";

import posts from "./posts";
import sections from "./sections";
import search from "./search";
import feedback from "./feedback";
import regions from "./regions";

export default {
  posts: posts(instance),
  sections: sections(instance),
  search: search(instance),
  feedback: feedback(instance),
  regions: regions(instance),
};
