<template>
  <div class="search-result-card">
    {{ types }}
    <a
      class="block"
      :target="
        this.searchResult?.id_post_type === LINK_POST_TYPE ? '_blank' : '_self'
      "
      :href="createURL()"
    >
      <div class="search-result-card-content">
        <p class="search-result-card-date">{{ searchResult?.updated_at }}</p>
        <h1 class="search-result-card-title">{{ searchResult?.title }}</h1>
      </div>
    </a>
  </div>
</template>

<script>
import types from "@/config/types.yml";

export default {
  props: {
    searchResult: Object,
    searchQuery: String,
  },
  data() {
    return {
      LINK_POST_TYPE: types.post.link,
      NEWS_POST_TYPE: types.post.news,
    };
  },
  methods: {
    createURL() {
      if (this.searchResult.id_post_type === this.NEWS_POST_TYPE) {
        return `news/${this.searchResult.url}?search=${this.searchQuery}`;
      } else if (this.searchResult.id_post_type === this.LINK_POST_TYPE) {
        return this.searchResult.url;
      } else if (
        this.searchResult.sections.url &&
        this.searchResult.sections.url !== "/"
      ) {
        return `${this.searchResult.sections.url}/${this.searchResult.url}?search=${this.searchQuery}`;
      }

      return `common/${this.searchResult.url}?search=${this.searchQuery}`;
    },
  },
};
</script>
