<template>
  <div class="news-card">
    <a :href="`news/${news?.url}`">
      <div
        class="news-main-img"
        :style="
          news?.img
            ? getBgImg(siteURL, news?.img)
            : `background-image: url(${getDefaultImg('news_item.png')});`
        "
      ></div>
      <div class="news-card-content">
        <p class="news-card-date">{{ news?.updated_at }}</p>
        <p class="news-card-text" v-html="news?.title"></p>
      </div>
    </a>
  </div>
</template>

<script>
import StylesMixin from "@/mixins/styles";

export default {
  mixins: [StylesMixin],
  props: {
    news: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getDefaultImg(img) {
      return require("../../../assets/img/content/" + img);
    },
  },
};
</script>
