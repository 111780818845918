export default {
  methods: {
    showSearchedText(text) {
      let searchWords = this.$route.query.search.trim().split(" ");

      return searchWords.reduce((temp, word) => {
        if (word) {
          let regex = new RegExp(word, "gi");

          return (temp = temp.replace(
            regex,
            `<span class="search-word">${
              temp.match(regex) ? temp.match(regex)[0] : ""
            }</span>`
          ));
        } else {
          return temp;
        }
      }, text);
    },
  },
};
