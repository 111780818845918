<template>
  <div class="navbar-menu-btn">
    <input type="checkbox" v-model="toggle" />
    <div class="hamburger-lines flex flex-column justify-content-between">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },
};
</script>
