/* eslint-disable no-useless-catch */
import api from "@/api/public/index";
import types from "@/config/types.yml";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

export default {
  state: {
    pageData: DEFAULT_DATA,
    newsData: DEFAULT_DATA,
    importantLinksData: DEFAULT_DATA,
    contactsData: DEFAULT_DATA,
  },
  mutations: {
    setPublicPostsData(state, payload) {
      switch (payload.postType) {
        case types.post.page:
          state.pageData = payload.data;
          break;
        case types.post.news:
          state.newsData = payload.data;
          break;
        case types.post.link:
          state.importantLinksData = payload.data;
          break;
        case types.post.contact:
          state.contactsData = payload.data;
          break;
        default:
          break;
      }
    },
    setDefaultPublicPostsData(state) {
      state.pageData = DEFAULT_DATA;
      state.newsData = DEFAULT_DATA;
      state.importantLinksData = DEFAULT_DATA;
      state.contactsData = DEFAULT_DATA;
    },
  },
  actions: {
    async getPublicPostsData({ commit }, payload) {
      try {
        const postsData = (await api.posts.getPostsDataByType(payload.filters))
          .data;

        commit("setPublicPostsData", {
          data: postsData,
          postType: payload.postType,
        });
      } catch (e) {
        throw e;
      }
    },
    clearPublicPostsData({ commit }) {
      commit("setDefaultPublicPostsData");
    },
  },
  getters: {
    latestNews: (state) => (size) => {
      if (state.newsData.rows?.length) {
        return state.newsData.rows.length > 3
          ? state.newsData.rows.slice(0, size)
          : state.newsData.rows;
      }
    },
    importantLinks: (state) => (size) => {
      if (state.importantLinksData.rows?.length) {
        return state.importantLinksData.rows.length > 3
          ? state.importantLinksData.rows.slice(0, size)
          : state.importantLinksData.rows;
      }
    },
    single: (state) => (type) => {
      switch (type) {
        case types.post.news:
          return state.newsData.rows?.length
            ? state.newsData.rows[0]
            : state.newsData.rows;
        case types.post.page:
          return state.pageData.rows?.length
            ? state.pageData.rows[0]
            : state.pageData.rows;
        default:
          break;
      }
    },
    siteContacts: (state) => (id) => {
      if (state.contactsData.rows?.length) {
        return state.contactsData.rows.filter((el) => el.url === id)[0];
      }

      return null;
    },
  },
};
