/* eslint-disable no-useless-catch */
import api from "@/api/administration/index";

const DEFAULT_DATA = {
  count: 0,
  pages: 0,
  rows: [],
  total_rows: 0,
};

const COMMON_SECTION = {
  id: 0,
  name: "Без розділу",
  url: null,
};

export default {
  state: {
    sectionsData: DEFAULT_DATA,
  },
  mutations: {
    setSectionsData(state, payload) {
      state.sectionsData = payload;
    },
    setDefaultSectionsData(state) {
      state.sectionsData = DEFAULT_DATA;
    },
  },
  actions: {
    async getSectionsData({ commit }, payload) {
      try {
        const sectionsData = (await api.sections.getSectionsData(payload)).data
          .rows;

        commit("setSectionsData", sectionsData);
      } catch (e) {
        throw e;
      }
    },
    async updateSectionData({ dispatch }, payload) {
      try {
        await api.sections.updateSection(payload.id, payload.section_data);

        dispatch("getSectionsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    async createSection({ dispatch }, payload) {
      try {
        await api.sections.createSection(payload.section_data);

        dispatch("getSectionsData", payload.filters);
      } catch (e) {
        throw e;
      }
    },
    clearSectionsData({ commit }) {
      commit("setDefaultSectionsData");
    },
  },
  getters: {
    pagesList: (state) => {
      let pagesList = [];

      if (state.sectionsData && state.sectionsData.length > 0) {
        pagesList = [
          ...state.sectionsData.filter((el) => el.available === 1),
          COMMON_SECTION,
        ];
      }

      return pagesList;
    },
    sectionByID: (state) => (payload) => {
      return state.sectionsData && state.sectionsData.length > 0
        ? state.sectionsData.filter((el) => el.id === payload)[0]
        : null;
    },
  },
};
