import helpers from "@/helpers";

export default (instance) => {
  return {
    getSections() {
      return instance
        .get("api/sections")
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
  };
};
