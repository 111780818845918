<template>
  <PageHeader />
  <main class="main-section">
    <div class="p-container">
      <div class="grid">
        <div class="lg:col-6 xl:col-7 flex align-items-center">
          <div
            class="main-contact-list text-center lg:text-left"
            v-html="siteContacts(CONTACTS_MAIN)?.text"
          ></div>
        </div>
        <div class="hidden lg:block lg:col-6 xl:col-5">
          <LatestNews :latestNews="latestNewsList" />
        </div>
      </div>
    </div>
  </main>
  <section class="lg:hidden latest-news-section">
    <div class="p-container">
      <LatestNews :latestNews="latestNewsList" />
    </div>
  </section>
  <section class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1 class="content-header-text relative">
          <span>Важливі посилання</span>
          <router-link
            class="hidden md:inline all-links"
            :to="{ name: 'links' }"
          >
            Всі посилання
          </router-link>
        </h1>
        <hr class="content-header-divider" />
      </div>
      <div class="content-wrapper">
        <div class="important-links-list">
          <div class="grid justify-content-center">
            <div
              class="col-12 md:col-6 lg:col-4"
              v-for="link in importantLinksList"
              :key="link.id"
            >
              <ImportantLinkCard :link="link" />
            </div>
          </div>
          <div class="md:hidden mt-4">
            <router-link class="all-links" :to="{ name: 'links' }">
              Всі посилання
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import LatestNews from "@/components/public/content/LatestNews.vue";
import ImportantLinkCard from "@/components/public/content/ImportantLinkCard.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    PageHeader,
    PageFooter,
    LatestNews,
    ImportantLinkCard,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      CONTACTS_MAIN: "contacts-main",

      postTypes: [types.post.news, types.post.link],
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortLinksFilter: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      sortNewsFilter: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      filters: {},
      page: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapGetters(["latestNews", "importantLinks", "siteContacts"]),

    importantLinksList() {
      return this.importantLinks(3);
    },
    latestNewsList() {
      return this.latestNews(2);
    },
  },
  methods: {
    ...mapActions(["getPublicPostsData", "clearPublicPostsData"]),
  },
  async mounted() {
    try {
      this.postTypes.forEach(async (type) => {
        this.filterPostsByType.value = type;

        switch (type) {
          case types.post.news:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.sortNewsFilter,
              this.filterPostsIsPubliched,
            ]);
            break;
          case types.post.link:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.filterPostsIsPubliched,
              this.sortLinksFilter,
            ]);
            break;
          default:
            break;
        }

        await this.getPublicPostsData({
          filters: this.filters,
          postType: type,
        });
      });
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
