<template>
  <div class="sticky top-menu-bar">
    <Menubar>
      <template #start>
        <Button
          outlined
          size="small"
          severity="secondary"
          class="md:hidden"
          v-tooltip.right="'Відкрити меню'"
          :icon="visible ? 'pi pi-times' : 'pi pi-bars'"
          @click="$emit('update:visible', !visible)"
        />
      </template>
      <template #end>
        <div class="flex gap-2">
          <div class="flex align-items-center">
            <span class="font-bold ml-2"> Панель керування </span>
          </div>
          <div class="user-menu">
            <Button
              outlined
              icon="pi pi-cog"
              size="small"
              severity="secondary"
              v-tooltip.right="'Відкрити налаштування'"
              @click="toggle"
            />
            <Menu
              ref="menu"
              id="overlay_menu"
              :model="menubarItems"
              :popup="true"
            >
              <template #start>
                <button
                  @click="profileClick"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <div class="flex flex-column align">
                    <span class="font-bold">{{ userFullName }}</span>
                  </div>
                </button>
              </template>
              <template #end>
                <button
                  class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
                  @click="logout()"
                >
                  <i class="pi pi-sign-out" />
                  <span class="ml-2">Вийти з системи</span>
                </button>
              </template>
            </Menu>
          </div>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Button from "primevue/button";

export default {
  components: {
    Menubar,
    Button,
    Menu,
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      menubarItems: [{ separator: true }],
    };
  },
  computed: {
    ...mapGetters(["userFullName"]),
  },
  methods: {
    ...mapActions(["signOutAdmin"]),

    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    async logout() {
      try {
        await this.signOutAdmin();

        this.$router.push("/");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
