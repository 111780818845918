<template>
  <div class="flex flex-wrap gap-2 justify-content-center">
    <Button
      v-show="showEditBtn"
      rounded
      type="button"
      icon="pi pi-pencil"
      severity="success"
      v-tooltip.bottom="'Редагувати'"
      @click="$emit('editFunc')"
    />
    <Button
      v-show="showRemoveBtn"
      rounded
      type="button"
      icon="pi pi-trash"
      severity="danger"
      v-tooltip.bottom="'Видалити'"
      @click="confirm2()"
    />
  </div>
</template>

<script>
import Button from "primevue/button";

import DialogMixin from "@/mixins/dialog";

export default {
  components: { Button },
  mixins: [DialogMixin],
  props: {
    itemName: String,
    showEditBtn: {
      type: Boolean,
      default: true,
    },
    showRemoveBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["editFunc", "removeFunc"],
  methods: {
    confirm2() {
      this.createConfirm({
        message: "Підтвердіть видалення",
        header: `Видалити ${this.itemName}?`,
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Видалити",
        rejectLabel: "Відмінити",
        defaultFocus: "reject",
        accept: () => {
          this.$emit("removeFunc");
        },
        reject: () => {},
      });
    },
  },
};
</script>
