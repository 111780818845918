<template>
  <div class="search-form-wrapper w-full">
    <div class="p-container">
      <hr class="mobile-navbar-divider hidden lg:block" />

      <form
        action=""
        class="sm:flex sm:gap-2 search-form"
        @submit.prevent="search()"
      >
        <div class="w-full">
          <input
            v-model="searchQuery"
            type="text"
            class="search-form-field"
            placeholder="Уведіть ключові слова"
          />
          <label
            for="search"
            :class="['search-field-label', searchError ? 'inline' : 'hidden']"
          >
            {{
              searchError
                ? "Мінімальна кількість символів - 3"
                : "Пошук по сайту"
            }}
          </label>
        </div>
        <button type="submit" class="search-form-btn" :disabled="searchError">
          Знайти
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  emits: ["update:showSearchForm", "closeMobileNavBar"],
  data() {
    return {
      searchError: false,
      searchQuery: null,
    };
  },
  watch: {
    searchQuery() {
      if (this.searchQuery) {
        this.searchError = this.searchQuery.length >= 3 ? false : true;
      }
    },
  },
  methods: {
    ...mapActions(["getItemsBySearchQuery"]),

    async search() {
      if (this.searchQuery?.length >= 3) {
        this.searchQuery = this.searchQuery.trim();

        if (this.$route.name === "search") {
          await this.getItemsBySearchQuery(this.searchQuery);

          this.$router.replace({
            query: { search: this.searchQuery },
          });

          this.$emit("closeMobileNavBar", true);
        } else {
          this.$router.push({
            name: "search",
            query: { search: this.searchQuery },
          });
        }

        this.$emit("update:showSearchForm", false);
        this.searchQuery = null;
      }
    },
  },
};
</script>
