import helpers from "@/helpers";

export default (adminURL, instance) => {
  return {
    getSectionsData(payload) {
      return instance
        .post(adminURL + "section/all", payload)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
    updateSection(id, payload) {
      return instance
        .put(adminURL + `section/${id}`, payload)
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
    createSection(payload) {
      return instance
        .post(adminURL + `section/create`, payload)
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
  };
};
