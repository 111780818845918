import AuthView from "../views/administration/AuthView.vue";
import WorkspaceView from "../views/administration/WorkspaceView.vue";
import EditPostView from "../views/administration/posts/EditPostView.vue";
import PostsView from "../views/administration/posts/PostsView.vue";
import UsersView from "../views/administration/UsersView.vue";

import types from "@/config/types.yml";

const ADMIN_PAGES = [
  {
    path: "admin",
    name: "admin-auth",
    meta: { isAdmin: true },
    component: AuthView,
  },
  {
    path: "workspace",
    component: WorkspaceView,
    meta: { requiresAdminAuth: true, isAdmin: true },
    children: [
      {
        path: "news",
        meta: { postType: types.post.news },
        children: [
          {
            path: "",
            name: "admin-news",
            component: PostsView,
          },
          {
            path: "create",
            name: "admin-create-news",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "admin-edit-news",
            component: EditPostView,
          },
        ],
      },
      {
        path: "pages",
        meta: { postType: types.post.page },
        children: [
          {
            path: "",
            name: "admin-pages",
            component: PostsView,
          },
          {
            path: "create",
            name: "admin-create-page",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "admin-edit-page",
            component: EditPostView,
          },
        ],
      },
      {
        path: "links",
        meta: { postType: types.post.link },
        children: [
          {
            path: "",
            name: "admin-links",
            component: PostsView,
          },
          {
            path: "create",
            name: "admin-create-link",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "admin-edit-link",
            component: EditPostView,
          },
        ],
      },
      {
        path: "users",
        name: "admin-users",
        component: UsersView,
      },
      {
        path: "contacts",
        meta: { postType: 4 },
        children: [
          {
            path: "",
            name: "admin-contacts",
            component: PostsView,
          },
          {
            path: "create",
            name: "admin-create-contact",
            component: EditPostView,
          },
          {
            path: "edit/:id",
            name: "admin-edit-contact",
            component: EditPostView,
          },
        ],
      },
    ],
  },
];

export default ADMIN_PAGES;
