export default function (instance) {
  return {
    sendFeedback(payload) {
      return instance
        .post("api/feedback", payload, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .catch((error) => {
          throw error;
        });
    },
  };
}
