<template>
  <li
    :class="[
      'navbar-menu-item lg:text-center',
      menuItem.children ? 'parent' : '',
      showSubmenuList === menuItem.id ? 'rotate' : '',
    ]"
    :data-id="menuItem.id"
    @click="
      showSubmenuList === menuItem.id
        ? $emit('update:showSubmenuList', null)
        : $emit('update:showSubmenuList', menuItem.id)
    "
  >
    <a v-if="!menuItem.available" :href="menuItem.url">
      <span class="navbar-menu-item-title">{{ menuItem.name }}</span>
    </a>
    <span v-else class="navbar-menu-item-title">
      {{ menuItem.name }}
    </span>
    <div
      v-show="menuItem.sub_sections && showSubmenuList === menuItem.id"
      class="navbar-submenu-wrapper"
    >
      <div class="p-container">
        <hr class="mobile-navbar-divider" />
        <ul class="navbar-submenu-list grid">
          <li
            v-for="submenuItem in menuItem.sub_sections"
            class="col-12 lg:col-4 navbar-submenu-item text-left"
            :key="submenuItem.id"
            @click="$emit('update:showSubmenuList', null)"
          >
            <a
              v-if="submenuItem.url"
              :href="
                submenuItem.url_is_external
                  ? submenuItem.url
                  : `${menuItem.url}/${submenuItem.url}`
              "
            >
              {{ submenuItem.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    menuItem: Object,
    showSubmenuList: Boolean,
  },
};
</script>
