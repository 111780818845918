<template>
  <TopMenuBar v-model:visible="showSideBar" />

  <div class="workspace">
    <div class="grid">
      <div :class="showSideBar ? 'sm:col-3 xl:col-2' : 'd-none'">
        <SideMenuBar
          v-model:showSideBar="showSideBar"
          :sideBarMenuItems="
            $route.meta.isAdmin ? adminMenuItems : managerMenuItems
          "
          :isMobile="isMobile"
        />
      </div>
      <div :class="showSideBar ? 'sm:col-9 xl:col-10' : 'sm:col-12'">
        <div class="worckspace-content" :key="$route.name">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog class="w-9 md:w-6 xl:w-3" />
</template>

<script>
import TopMenuBar from "@/components/administration/ui/TopMenuBar.vue";
import SideMenuBar from "@/components/administration/ui/SidebarMenu.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  name: "WorkspasePanelView",
  components: {
    TopMenuBar,
    SideMenuBar,
    ConfirmDialog,
  },
  data() {
    return {
      windowWidth: null,
      showSideBar: true,
      isMobile: false,
      adminMenuItems: [
        {
          label: "Новини",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Створити нову",
              icon: "pi pi-fw pi-plus",
              to: { name: "admin-create-news" },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "admin-news" },
            },
          ],
        },
        {
          label: "Сторінки",
          icon: "pi pi-fw pi-book",
          items: [
            {
              label: "Створити нову",
              icon: "pi pi-fw pi-plus",
              to: { name: "admin-create-page" },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "admin-pages" },
            },
          ],
        },
        {
          label: "Важливі посилання",
          icon: "pi pi-fw pi-link",
          items: [
            {
              label: "Створити нове",
              icon: "pi pi-fw pi-plus",
              to: { name: "admin-create-link" },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "admin-links" },
            },
          ],
        },
        {
          label: "Контакти",
          icon: "pi pi-fw pi-phone",
          items: [
            {
              label: "Додати нові контакти",
              icon: "pi pi-fw pi-plus",
              to: { name: "admin-create-contact" },
            },
            {
              label: "Переглянути всі",
              icon: "pi pi-fw pi-list",
              to: { name: "admin-contacts" },
            },
          ],
        },
        {
          label: "Користувачі",
          icon: "pi pi-fw pi-user",
          to: { name: "admin-users" },
        },
      ],
    };
  },
  async mounted() {
    this.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.showSideBar = false;
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.showSideBar = true;
      }
    });
  },
};
</script>
