<template>
  <PageHeader />
  <PageBreadcrumbs v-if="singlePage" :items="breadcrumbsList" />
  <article class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          {{ singlePage?.title }}
        </h1>
        <hr class="content-header-divider" />
      </div>
      <div class="grid">
        <div class="col-12 lg:col-8">
          <div
            class="single-page-content-wrapper"
            v-html="
              singlePage?.text && $route.query.search
                ? showSearchedText(singlePage?.text)
                : singlePage?.text
            "
          ></div>
        </div>
        <div class="col-12 lg:col-4">
          <PageSidebar :links="importantLinksList" />
        </div>
      </div>
    </div>
  </article>

  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PageSidebar from "@/components/public/page/PageSidebar.vue";
import PageBreadcrumbs from "@/components/public/page/PageBreadcrumbs.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";
import StylesMixin from "@/mixins/styles";
import SearchMixin from "@/mixins/search";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SingleView",
  components: {
    PageHeader,
    PageFooter,
    PageSidebar,
    PageBreadcrumbs,
  },
  mixins: [FiltersMixin, StylesMixin, SearchMixin],
  data() {
    return {
      breadcrumbs: {
        items: [],
      },
      postTypes: [types.post.page, types.post.link],
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      filterPostsByURL: {
        field: filters.posts.filterByURL,
        value: null,
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortLinksFilter: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
    };
  },
  computed: {
    ...mapState({
      sections: (state) => state.public_sections.sections,
    }),
    ...mapGetters(["single", "importantLinks"]),

    singlePage() {
      return this.single(types.post.page);
    },
    importantLinksList() {
      return this.importantLinks(5);
    },
    breadcrumbsList() {
      if (this.singlePage.id_section) {
        return [
          {
            label: this.sections.find(
              (section) => section.id === this.singlePage.id_section
            )?.name,
          },
          {
            label: this.singlePage.title,
          },
        ];
      } else {
        let section = this.sections.find(
          (section) => section.url === `/${this.$route.params.section}`
        );

        return [
          {
            label: section?.name ? section?.name : "Стаття",
            to: section?.url,
          },
          {
            label: this.singlePage.title,
          },
        ];
      }
    },
  },
  methods: {
    ...mapActions(["getPublicPostsData", "clearPublicPostsData"]),
  },
  async mounted() {
    this.filterPostsByURL.value = this.$route.params.page
      ? this.$route.params.page[0]
      : this.$route.params.section;

    try {
      this.postTypes.forEach(async (type) => {
        this.filterPostsByType.value = type;

        switch (type) {
          case types.post.page:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.filterPostsByURL,
              this.filterPostsIsPubliched,
            ]);
            break;
          case types.post.link:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.filterPostsIsPubliched,
              this.sortLinksFilter,
            ]);
            break;
          default:
            break;
        }

        await this.getPublicPostsData({
          filters: this.filters,
          postType: type,
        });
      });
    } catch (e) {
      console.log(e);
    }
  },
  updated() {
    if (!this.singlePage) {
      this.$router.push({ name: "404-page" });
    }
  },
  unmounted() {
    this.clearPublicPostsData();
  },
};
</script>
