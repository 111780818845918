<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="content mt-3">
    <PostsTable
      :posts="postsData?.rows"
      :title="routerList[$route.name].label"
      :filters="filters"
      :routerList="routerList"
      @getItemsByFilter="
        (filter) => {
          filters = createFilters(filters, [filter, sortByDate]);
          saveFiltersInStorage(
            filters,
            'post_filters',
            this.$route.meta.postType
          );

          getPosts();
        }
      "
      @clearFilters="
        () => {
          filters = clearFilters('post_filters');

          getPosts();
        }
      "
    />
  </div>
  <div class="content mt-3 p-2">
    <TablePaginator
      :rows="postsData.count"
      :totalRows="postsData.total_rows"
      @getItemsByPage="
        (event) => {
          changePage(filters, event);
          saveFiltersInStorage(
            filters,
            'post_filters',
            this.$route.meta.postType
          );

          getPosts();
        }
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import BreadcrumbsList from "@/components/administration/ui/BreadcrumbsList.vue";
import TablePaginator from "@/components/administration/ui/TablePaginator.vue";
import PostsTable from "@/components/administration/posts/PostsTable.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

export default {
  name: "PostsView",
  components: {
    BreadcrumbsList,
    TablePaginator,
    PostsTable,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      // breadcrumbs
      breadcrumbs: [],
      routerList: {
        "admin-news": {
          label: "Новини",
          name: "admin-news",
          createRoute: "admin-create-news",
          editRoute: "admin-edit-news",
        },
        "admin-pages": {
          label: "Сторінки",
          name: "admin-pages",
          createRoute: "admin-create-page",
          editRoute: "admin-edit-page",
        },
        "admin-links": {
          label: "Посилання",
          name: "admin-link",
          createRoute: "admin-create-link",
          editRoute: "admin-edit-link",
        },
        "admin-contacts": {
          label: "Контакти",
          name: "admin-contacts",
          createRoute: "admin-create-contact",
          editRoute: "admin-edit-contact",
        },
      },

      // post filters
      filterPostsBySectionsID: {
        field: filters.posts.filterBySectionID,
        value: null,
      },
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      sortByDate: {
        field: filters.posts.sortByDate,
        sort: "DESC",
      },
      sortByPosition: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      filters: { page: 1, pageSize: 20 },
    };
  },
  computed: {
    ...mapState({
      postsData: (state) => state.posts.postsData,
    }),

    ...mapGetters(["isAdmin"]),
  },
  methods: {
    ...mapActions(["getPostsData", "clearPostsData", "getSectionsData"]),

    async getPosts() {
      let localFilters =
        JSON.parse(localStorage.getItem("post_filters")) ?? this.filters;

      this.filters = localFilters[this.$route.meta.postType] ?? this.filters;

      this.filters = this.createFilters(this.filters, [
        this.filterPostsByType,
        this.$route.meta.postType === types.post.link
          ? this.sortByPosition
          : this.sortByDate,
      ]);

      await this.getPostsData(this.filters);

      this.saveFiltersInStorage(
        this.filters,
        "post_filters",
        this.$route.meta.postType
      );
    },
  },
  async mounted() {
    this.breadcrumbs = [{ label: this.routerList[this.$route.name].label }];
    this.filterPostsByType.value = this.$route.meta.postType;

    try {
      if (this.$route.meta.postType === types.post.page) {
        await this.getSectionsData({
          pageSize: -1,
          filtered: [],
        });
      }

      await this.getPosts();
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearPostsData();
  },
};
</script>
