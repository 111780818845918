<template>
  <PageHeader />

  <article class="page-content">
    <div class="p-container">
      <div class="content-header mb-6">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          Результати пошуку
        </h1>
        <hr class="content-header-divider" />
        <h2 class="search-result-header">
          {{
            `Ви шукали «${$route.query.search}». ${
              searchingResult && searchingResult?.length > 0
                ? "Знайдено наступні публікації:"
                : "Нічого не знайдено."
            }`
          }}
        </h2>
      </div>
      <div class="grid">
        <div class="col-12 lg:col-8">
          <div class="single-page-content-wrapper">
            <SearchResultCard
              v-for="searchResult in searchingResult"
              :key="searchResult.id"
              :searchResult="searchResult"
              :searchQuery="$route.query.search"
            />
          </div>
        </div>
        <div class="col-12 lg:col-4">
          <PageSidebar :links="importantLinksList" />
        </div>
      </div>
    </div>
  </article>

  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PageSidebar from "@/components/public/page/PageSidebar.vue";
import SearchResultCard from "@/components/public/content/SearchResultCard.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleView",
  components: {
    PageHeader,
    PageFooter,
    PageSidebar,
    SearchResultCard,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: null,
      },
      sortLinksFilter: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
    };
  },
  computed: {
    ...mapGetters(["searchingResult", "importantLinks"]),

    importantLinksList() {
      return this.importantLinks(5);
    },
  },
  methods: {
    ...mapActions(["getPublicPostsData", "getItemsBySearchQuery"]),
  },
  async mounted() {
    this.filterPostsByType.value = types.post.link;
    this.filters = this.createFilters({}, [this.filterPostsByType]);

    try {
      await this.getItemsBySearchQuery(this.$route.query.search);
      await this.getPublicPostsData({
        filters: this.filters,
        postType: types.post.link,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
