export default {
  methods: {
    createDialog(component, payload) {
      const dialog = this.$dialog.open(component, payload);

      document.addEventListener("click", (ev) =>
        this.clickToCloseOutside(ev, dialog)
      );

      return dialog;
    },
    createConfirm(payload) {
      this.$confirm.require(payload);

      document.addEventListener("click", (ev) => this.clickToCloseOutside(ev));
    },
    clickToCloseOutside(ev, dialog) {
      if (
        ev.srcElement.classList.value
          .split(" ")
          .some((el) => el === "p-dialog-mask")
      ) {
        if (dialog) {
          dialog.close();
        } else {
          this.$confirm.close();
        }
      }
    },
  },
};
