<template>
  <PageHeader />
  <PageBreadcrumbs :items="breadcrumbs.items" />

  <article class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          Залишити звернення
        </h1>
        <hr class="content-header-divider" />
      </div>
      <div class="grid">
        <div class="col-12 lg:col-8">
          <div class="single-page-content-wrapper">
            <div
              v-html="
                singlePage?.text && $route.query.search
                  ? showSearchedText(singlePage?.text)
                  : singlePage?.text
              "
            ></div>
            <form class="feedback-form" @submit.prevent="sendFeedback">
              <h1 class="feedback-form-header text-center">Звернення</h1>
              <div class="input-wrapper p-float-label">
                <InputText
                  required
                  v-model="feedbackData.fullname"
                  type="text"
                  id="fullname"
                  :class="[
                    'feedback-form-input',
                    feedbackData.fullname === null || feedbackData.fullname
                      ? ''
                      : 'p-invalid',
                  ]"
                />
                <label for="fullname" class="feedback-form-input-label">
                  Прізвище, ім’я та по батькові
                </label>
              </div>
              <div class="input-wrapper p-float-label">
                <InputText
                  required
                  v-model="feedbackData.email"
                  type="email"
                  id="email"
                  :class="[
                    'feedback-form-input',
                    emailIsValid || !feedbackData.email ? '' : 'p-invalid',
                  ]"
                />
                <label for="phone" class="feedback-form-input-label">
                  Ваша електронна пошта
                </label>
              </div>
              <div class="input-wrapper p-float-label">
                <InputMask
                  required
                  type="text"
                  id="phone"
                  slotChar=""
                  v-model="feedbackData.phone"
                  :mask="'380999999999'"
                  :class="[
                    'feedback-form-input',
                    phoneIsValid || !feedbackData.phone ? '' : 'p-invalid',
                  ]"
                />
                <label for="phone" class="feedback-form-input-label">
                  Телефон
                </label>
              </div>
              <div class="input-wrapper p-float-label">
                <RegionsDropdown
                  v-model:selectedRegion="feedbackData.region"
                  :key="dropdownKey"
                  :regionsList="regions"
                />
              </div>
              <div class="input-wrapper p-float-label">
                <InputText
                  v-model="feedbackData.address"
                  type="text"
                  id="address"
                  class="feedback-form-input"
                />
                <label for="address" class="feedback-form-input-label">
                  Адреса проживання (населений пункт, адреса)
                </label>
              </div>
              <div class="input-wrapper p-float-label">
                <Textarea
                  required
                  v-model="feedbackData.text"
                  type="text"
                  class="feedback-form-input"
                />
                <label for="text" class="feedback-form-input-label">
                  Зміст звернення
                </label>
              </div>
              <div class="feedback-form-file-upload">
                <div class="feedback-form-input-file">
                  <div class="upload-wrapper md:flex">
                    <label for="upload-file"> Прикріпити файли </label>
                    <input
                      multiple
                      id="upload-file"
                      type="file"
                      @change="(e) => uploadFile(e)"
                    />
                    <button
                      v-if="feedbackFiles && feedbackFiles.length > 0"
                      class="feedback-form-file-upload-cancel"
                      @click="
                        () => {
                          feedbackFiles = [];
                          warningText = null;
                        }
                      "
                    >
                      Очистити
                    </button>
                  </div>
                  <div
                    :class="[
                      'block upload-file-name',
                      warningText || warningText === null ? 'empty' : '',
                    ]"
                  >
                    <span v-if="warningText" class="empty">{{
                      warningText
                    }}</span>
                    <span
                      v-else-if="feedbackFiles && feedbackFiles.length === 0"
                      class="empty"
                    >
                      <span>Файли не обрано (необов'язково)</span>
                      <span class="block">
                        Ви можете прикріпити до 5 файлів</span
                      >
                    </span>
                    <ul v-else>
                      <li v-for="file in feedbackFiles" :key="file">
                        {{ file.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="feedback-form-footer">
                <div class="grid">
                  <div class="col-12 md:col-6">
                    <button
                      type="submit"
                      class="feedback-form-submit-btn"
                      :disabled="!emailIsValid && !phoneIsValid"
                    >
                      Відправити
                    </button>
                  </div>
                  <div class="col-12 md:col-6">
                    <p class="feedback-form-warning p-0">
                      Натискаючи кнопку "Відправити" Ви надаєте згоду на обробку
                      персональних даних
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 lg:col-4">
          <PageSidebar :links="importantLinksList" />
        </div>
      </div>
    </div>
  </article>
  <ConfirmDialog class="public-confirm-dialog" group="publicConfirm">
    <template #message="slotProps">
      <ConfirmFeedback :data="slotProps.message" />
    </template>
  </ConfirmDialog>

  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PageSidebar from "@/components/public/page/PageSidebar.vue";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import ConfirmDialog from "primevue/confirmdialog";
import PageBreadcrumbs from "@/components/public/page/PageBreadcrumbs.vue";
import RegionsDropdown from "@/components/public/content/RegionsDropdown.vue";

import ConfirmFeedback from "@/components/public/content/ConfirmFeedback.vue";

import { mapActions, mapGetters, mapState } from "vuex";

import api from "@/api/public/index";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";
import helpers from "@/helpers";

import DialogMixin from "@/mixins/dialog";
import FiltersMixin from "@/mixins/filters";
import SearchMixin from "@/mixins/search";

const MAX_SIZE_FILE = 209715200; // 200 Mb
const MAX_FILES_QTT = 5;
const EMAIL_REGEX = /[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^380\d{9}$/;

const DEFAULT_FEEDBACK = {
  fullname: null,
  email: null,
  phone: null,
  region: null,
  address: null,
  text: null,
};

export default {
  name: "FeedbackView",
  components: {
    PageHeader,
    PageFooter,
    PageSidebar,
    InputMask,
    InputText,
    Textarea,
    ConfirmDialog,
    ConfirmFeedback,
    PageBreadcrumbs,
    RegionsDropdown,
  },
  mixins: [FiltersMixin, DialogMixin, SearchMixin],
  data() {
    return {
      breadcrumbs: {
        items: [
          {
            label: "Залишити звернення",
          },
        ],
      },
      postTypes: [types.post.page, types.post.link],

      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: types.post.link,
      },
      filterPostsByURL: {
        field: filters.posts.filterByURL,
        value: "zalishiti-zvernennya",
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortLinksFilter: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      filters: {},
      feedbackData: { ...DEFAULT_FEEDBACK },

      feedbackFiles: [],
      warningText: null,

      phoneIsValid: true,
      emailIsValid: true,

      dropdownKey: 0,
    };
  },
  computed: {
    ...mapGetters(["single", "importantLinks"]),
    ...mapState({
      regions: (state) => state.public_regions.regions,
    }),

    singlePage() {
      return this.single(types.post.page);
    },
    importantLinksList() {
      return this.importantLinks(5);
    },
  },
  watch: {
    "feedbackData.email"(email) {
      this.emailIsValid = EMAIL_REGEX.test(email);
    },
    "feedbackData.phone"(phone) {
      this.phoneIsValid = PHONE_REGEX.test(phone);
    },
  },
  methods: {
    ...mapActions([
      "getPublicPostsData",
      "getPublicRegionsData",
      "clearPublicRegionsData",
      "clearPublicPostsData",
    ]),

    async sendFeedback() {
      let feedbackFormData = new FormData();

      feedbackFormData.append("data", JSON.stringify(this.feedbackData));
      if (this.feedbackFiles) {
        this.feedbackFiles.forEach((file) => {
          feedbackFormData.append("files", file);
        });
      }

      if (!this.feedbackData.region) {
        helpers.notify.createUserNotify("error", "Оберіть область/регіон");
      } else {
        try {
          await api.feedback.sendFeedback(feedbackFormData);

          this.showFeedbackConfirm({
            header: "Ваше звернення відправлене!",
            isSuccessful: true,
          });
          this.feedbackData = { ...DEFAULT_FEEDBACK };
          this.dropdownKey++;
          this.feedbackFiles = [];
        } catch (e) {
          this.showFeedbackConfirm({
            header: "Помилка відправки звернення - спробуйте пізніше",
            isSuccessful: false,
          });
        }
      }
    },
    uploadFile(e) {
      this.feedbackFiles = [];

      if (!e.target.files.length) return;

      let files = Array.from(e.target.files);
      if (files.length <= MAX_FILES_QTT) {
        files.forEach((file) => {
          if (!/\.(docx?|xlsx?|pdf|txt|jpe?g|png)$/i.test(file.name)) {
            this.warningText = "Формат не підтримується!";
            this.feedbackFiles = [];
          } else if (file.size > MAX_SIZE_FILE) {
            this.warningText = "Занадто великий розмір файлу!";
            this.feedbackFiles = [];
            return;
          } else {
            this.warningText = "";
            this.feedbackFiles.push(file);
          }
        });
      } else {
        this.warningText = `Кількість файлів перевищує ${MAX_FILES_QTT}`;
        this.feedbackFiles = [];
        return;
      }
    },
    showFeedbackConfirm(message) {
      this.$confirm.require({
        group: "publicConfirm",
        icon: "pi pi-exclamation-triangle",
        message: message,
        acceptLabel: "Добре",
        rejectClass: "hidden",
      });

      document.addEventListener("click", (ev) => this.clickToCloseOutside(ev));
    },
  },
  async mounted() {
    this.filters = this.createFilters({}, [this.filterPostsByType]);

    try {
      this.postTypes.forEach(async (type) => {
        this.filterPostsByType.value = type;

        switch (type) {
          case types.post.page:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.filterPostsByURL,
              this.filterPostsIsPubliched,
            ]);

            break;
          case types.post.link:
            this.filters = this.createFilters({}, [
              this.filterPostsByType,
              this.filterPostsIsPubliched,
              this.sortLinksFilter,
            ]);
            break;
          default:
            break;
        }

        await this.getPublicPostsData({
          filters: this.filters,
          postType: type,
        });

        await this.getPublicRegionsData();
      });
    } catch (e) {
      console.log(e);
    }
  },
  updated() {
    if (!this.singlePage) {
      this.$router.push({ name: "404-page" });
    }
  },
  unmounted() {
    this.clearPublicPostsData();
    this.clearPublicRegionsData();
  },
};
</script>
