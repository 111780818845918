<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="grid">
    <div class="col-12 lg:col-8">
      <div class="content mt-3 post-title-wrapper">
        <h3 class="mt-0 mb-2">Заголовок</h3>
        <InputText v-model="post.title" type="text" class="w-full" required />

        <h3 class="mt-3 mb-1">
          <span class="vertical-align-baseline">
            {{
              $route.meta.postType === CONTACT_POST_TYPE
                ? "ID блоку"
                : "Посилання"
            }}
          </span>
        </h3>

        <div class="md:flex text-center md:align-items-center">
          <span
            class="block md:flex flex align-items-center mb-2 md:mb-0 w-full"
          >
            <span
              v-show="
                !externalLink &&
                post.id_section !== COMMON_SECTION &&
                ($route.meta.postType === PAGE_POST_TYPE ||
                  $route.meta.postType === NEWS_POST_TYPE)
              "
            >
              {{ itemPath }}
            </span>
            <InputText v-model="tempURL" type="text" class="line w-full" />
          </span>
          <ToggleButton
            v-if="
              $route.meta.postType !== CONTACT_POST_TYPE &&
              post.id_section !== null
            "
            v-model="externalLink"
            class="w-5 ml-2"
            onLabel="Зовнішнє"
            offLabel="Внутрішнє"
            v-tooltip.bottom="
              externalLink ? 'Зовнішнє посилання' : 'Внутрішнє посилання'
            "
            @click="createLinkPath()"
          />
          <Button
            v-show="
              !externalLink &&
              ($route.meta.postType === PAGE_POST_TYPE ||
                $route.meta.postType === NEWS_POST_TYPE)
            "
            raised
            icon="pi pi-copy"
            severity="secondary"
            class="url-copy-btn w-3 ml-2"
            v-tooltip.bottom="'Копіювати посилання'"
            @click="copyURL()"
          />
        </div>
      </div>
      <div class="content mt-3 post-short-text-wrapper">
        <h3 class="mt-0 mb-2">Короткий опис</h3>
        <VueTrumbowyg
          v-model="post.short_text"
          class="form-control post_description_editor"
          name="short_text"
          :config="shortEditorConfig"
        />
      </div>
      <div class="content mt-3 post-text-wrapper" :key="$route.name">
        <h3 class="mt-0 mb-2">
          Текст
          {{
            $route.meta.postType === CONTACT_POST_TYPE
              ? "блоку контактів"
              : "публікації"
          }}
        </h3>
        <VueTrumbowyg
          v-model="post.text"
          class="form-control post_text_editor"
          name="text"
          :config="fullEditorConfig"
        />
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="content mt-3">
        <div class="grid gap-3 flex justify-content-center">
          <ToggleButton
            v-model="isPublished"
            onLabel="Опублікувати"
            offLabel="Як чернетка"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            v-tooltip.bottom="'Статус публікації'"
          />
          <Button
            label="Зберегти"
            severity="secondary"
            text
            raised
            :disabled="!post.title"
            @click="savePost(post)"
          />
        </div>
      </div>
      <div v-if="$route.meta.postType === PAGE_POST_TYPE" class="content mt-3">
        <h3 class="mt-0 mb-1">Розділ</h3>
        <Dropdown
          editable
          v-model="post.id_section"
          optionValue="id"
          optionLabel="name"
          placeholder="Розділ для публікації"
          class="w-full"
          :options="pagesList"
          @change="
            () => {
              createLinkPath();
              createURLPath(this.post.title);
            }
          "
        />
      </div>
      <div v-if="$route.meta.postType === LINK_POST_TYPE" class="content mt-3">
        <div class="mb-3">
          <h3 class="mt-0 mb-1">Позиція</h3>
          <InputText
            v-model.number="post.position"
            type="number"
            class="w-full"
          />
        </div>
      </div>
      <div
        v-if="
          $route.meta.postType === NEWS_POST_TYPE ||
          $route.meta.postType === LINK_POST_TYPE
        "
        class="uploading-files"
      >
        <div class="content mt-3">
          <UploadImgPanel
            :title="'Головне зображення'"
            :id="'header_img'"
            :img="headerImg"
            :imgSrc="post.img"
            @update:img="headerImg = $event"
            @update:imgSrc="post.img = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <div ref="reference"></div>
</template>

<script>
import BreadcrumbsList from "@/components/administration/ui/BreadcrumbsList.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";
import VueTrumbowyg from "vue-trumbowyg";
import Dropdown from "primevue/dropdown";
import UploadImgPanel from "@/components/administration/ui/UploadImgPanel.vue";

import FiltersMixin from "@/mixins/filters";

import editor_config from "@/config/editor.yml";
import transliteration_config from "@/config/transliteration.yml";
import types_config from "@/config/types.yml";
import config from "@/config/filters.yml";

import { mapActions, mapGetters, mapState } from "vuex";

import helpers from "@/helpers";

// trumbowyg styles
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";
import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";

// trumbowyg plugins
import "trumbowyg/dist/plugins/base64/trumbowyg.base64.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/indent/trumbowyg.indent.min.js";
import "trumbowyg/dist/plugins/table/trumbowyg.table.min.js";
import "trumbowyg/dist/plugins/upload/trumbowyg.upload.min.js";
import "trumbowyg/dist/plugins/resizimg/trumbowyg.resizimg.min.js";
import "trumbowyg/dist/langs/ua.min.js";
import "jquery-resizable-dom/dist/jquery-resizable";
import "trumbowyg/dist/plugins/template/trumbowyg.template.min.js";

export default {
  name: "PostEditor",
  components: {
    BreadcrumbsList,
    InputText,
    Button,
    ToggleButton,
    VueTrumbowyg,
    Dropdown,
    UploadImgPanel,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      //post types
      PAGE_POST_TYPE: types_config.post.page,
      NEWS_POST_TYPE: types_config.post.news,
      LINK_POST_TYPE: types_config.post.link,
      CONTACT_POST_TYPE: types_config.post.contact,

      COMMON_SECTION: 0,

      // breadcrumbs
      breadcrumbs: [],
      routerList: {
        "admin-create-news": {
          label: "Створити новину",
          parent: {
            label: "Новини",
            name: "admin-news",
          },
        },
        "admin-edit-news": {
          parent: {
            label: "Новини",
            name: "admin-news",
          },
        },
        "admin-create-page": {
          label: "Створити сторінку",
          parent: {
            label: "Сторінки",
            name: "admin-pages",
          },
        },
        "admin-edit-page": {
          parent: {
            label: "Сторінки",
            name: "admin-pages",
          },
        },
        "admin-create-link": {
          label: "Створити посилання",
          parent: {
            label: "Посилання",
            name: "admin-links",
          },
        },
        "admin-edit-link": {
          parent: {
            label: "Посилання",
            name: "admin-links",
          },
        },
        "admin-create-contact": {
          label: "Створити посилання",
          parent: {
            label: "Контакти",
            name: "admin-contacts",
          },
        },
        "admin-edit-contact": {
          parent: {
            label: "Контакти",
            name: "admin-contacts",
          },
        },
      },

      siteURL: process.env.VUE_APP_API_URL,

      // editor config
      shortEditorConfig: editor_config.short_editor_config,
      fullEditorConfig: this.createFullEditorConfig(),

      // post data
      post: {
        id_post_type: null,
        id_section: null,
        position: 999,
        url: null,
        url_is_external: 0,
        title: null,
        short_text: null,
        text: null,
        img: null,
        bg_img: null,
        bg_color: null,
        text_color: null,
        options: null,
        is_published: null,
      },

      headerImg: null,

      isPublished: false,
      externalLink: false,

      // post filters
      filterByPostID: {
        field: config.posts.filterByID,
        value: null,
      },
      filterPostsBySectionsID: {
        field: config.posts.filterBySectionID,
        value: null,
      },
      filters: {},
      itemPath: "",
      tempURL: "",
    };
  },
  computed: {
    ...mapState({
      postsData: (state) => state.posts.postsData,
      sectionsData: (state) => state.sections.sectionsData,
    }),
    ...mapGetters(["isAdmin", "sectionByField", "pagesList", "adminToken"]),

    postData() {
      return this.postsData.rows[0];
    },
  },
  watch: {
    "post.title"(newTitle) {
      if (
        this.$route.meta.postType === this.PAGE_POST_TYPE ||
        this.$route.meta.postType === this.NEWS_POST_TYPE
      )
        this.createURLPath(newTitle);
    },
  },
  methods: {
    ...mapActions([
      "createPostData",
      "updatePostData",
      "clearPostsData",
      "getPostsData",
      "getSectionsData",
    ]),
    createLinkPath() {
      let sectionURL =
        this.$route.meta.postType === this.PAGE_POST_TYPE
          ? this.pagesList.filter((el) => el.id === this.post.id_section)[0].url
          : "";

      if (!this.externalLink || this.post.id_section !== this.COMMON_SECTION) {
        switch (this.$route.meta.postType) {
          case this.NEWS_POST_TYPE:
            this.itemPath = `news/`;
            break;
          case this.PAGE_POST_TYPE:
            this.itemPath = sectionURL ? `${sectionURL}/` : "/";
            break;
          default:
            break;
        }
      } else {
        this.itemPath = "";
      }
    },
    async savePost(post) {
      if (
        this.$route.meta.postType === this.LINK_POST_TYPE &&
        !this.headerImg &&
        !this.post.img
      ) {
        helpers.notify.createUserNotify("error", "Оберіть зображення!");
      } else {
        post.is_published = this.isPublished ? 1 : 0;
        post.url = this.tempURL;
        post.id_post_type = post.id_post_type
          ? post.id_post_type
          : this.$route.meta.postType;

        let postData = new FormData();

        postData.append("data", JSON.stringify(post));
        postData.append("img", this.headerImg);

        if (this.$route.params.id) {
          await this.updatePostData({
            id: this.$route.params.id,
            postData: postData,
          });
        } else {
          await this.createPostData({
            postData: postData,
          });
        }

        this.$router.go(-1);
      }
    },
    createURLPath(title) {
      if (title) {
        this.tempURL = title
          .toLowerCase()
          .split("")
          .map((char) => {
            if (
              transliteration_config.char_map[char] ||
              transliteration_config.char_map[char] === ""
            ) {
              return transliteration_config.char_map[char];
            } else {
              return char;
            }
          })
          .join("")
          .trim()
          .replace(/[^a-z0-9]/gm, "-");
      } else {
        this.tempURL = "";
      }
    },
    copyURL() {
      try {
        let fullURL = this.itemPath
          ? this.itemPath + this.tempURL
          : this.tempURL;

        const storage = document.createElement("textarea");
        storage.value = fullURL;

        this.$refs.reference.appendChild(storage);

        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand("copy");

        this.$refs.reference.removeChild(storage);

        helpers.notify.createUserNotify("success", "Посилання скопійовано");
      } catch (e) {
        helpers.notify.createUserNotify("warn", "Помилка копіювання посилання");
      }
    },
    createFullEditorConfig() {
      let config = editor_config.full_editor_config;
      config.plugins.upload = {
        serverPath: `${process.env.VUE_APP_API_URL}api/administration/pasteimg`,
        fileFieldName: "embedded_img",
        headers: {
          Authorization: `Bearer ${this.adminToken}`,
        },
        urlPropertyName: "img",
        statusPropertyName: "img",
      };

      return config;
    },
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        this.filterByPostID.value = this.$route.params.id;
        this.filters = this.createFilters(this.filters, [this.filterByPostID]);

        await this.getPostsData(this.filters);
        this.post = this.postData;

        this.isPublished = !!this.postData.is_published;
        this.externalLink = !!this.postData.url_is_external;
      }

      await this.getSectionsData({
        pageSize: -1,
        filtered: [],
      });

      this.createLinkPath();
    } catch (e) {
      console.log();
    }

    this.tempURL = this.post.url;

    this.breadcrumbs = [
      {
        label: this.routerList[this.$route.name].parent.label,
        to: { name: this.routerList[this.$route.name].parent.name },
      },
      {
        label: this.routerList[this.$route.name].label
          ? this.routerList[this.$route.name].label
          : this.post.title,
      },
    ];
  },
};
</script>
