<template>
  <footer class="footer">
    <div class="p-container">
      <SiteMap />
      <hr class="footer-divider" />
      <div class="flex flex-column md:flex-row">
        <div
          class="flex-order-2 md:flex-order-1 footer-site-info text-center md:text-left align-items-center w-full md:w-6"
        >
          <h1 class="footer-site-info-header">
            Черкаський обласний контактний центр
          </h1>
          <p class="footer-site-info-data">
            {{ `© ${getYear()} Черкаси` }}
          </p>
        </div>
        <div
          class="flex-order-1 md:flex-order-2 footer-contact-info text-center md:text-right w-full md:w-6 mb-5 md:mb-0"
        >
          <h1 class="footer-contact-header">Звертайтесь за телефонами:</h1>
          <div
            class="footer-contact-numbers"
            v-html="siteContacts(CONTACTS_FOOTER)?.text"
          ></div>
        </div>
      </div>
      <hr class="footer-divider" />
      <div
        class="footer-info flex flex-column lg:flex-row align-items-center lg:justify-content-center lg:align-items-end"
      >
        <span class="block lg:inline text-center">
          Сайт був розроблений за підтримки компанії
        </span>
        <a href="https://mclaut.com/">
          <img
            class="mt-2 md:ml-5 md:mt-0"
            src="@/assets/img/content/mclaut_logo.png"
          />
        </a>
      </div>
    </div>
  </footer>
  <PageScrollTopBtn />
</template>

<script>
import PageScrollTopBtn from "./PageScrollTopBtn.vue";
import SiteMap from "./footer/SiteMap.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PageScrollTopBtn,
    SiteMap,
  },
  data() {
    return {
      CONTACTS_FOOTER: "contacts-footer",
    };
  },
  computed: {
    ...mapGetters(["siteContacts"]),
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
