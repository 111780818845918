import helpers from "@/helpers";

export default (adminURL, instance) => {
  return {
    signIn(payload) {
      return instance
        .post(adminURL + "auth/signin", payload)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
    signOut() {
      return instance
        .get(adminURL + "auth/signout")
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
  };
};
