<template>
  <div class="important-link-card text-center">
    <a class="block important-link" target="_blank" :href="link?.url">
      <img v-if="link?.img" :src="siteURL + link?.img" />
    </a>
  </div>
</template>

<script>
import StylesMixin from "@/mixins/styles";

export default {
  mixins: [StylesMixin],
  props: {
    link: Object,
  },
  data() {
    return {
      siteURL: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
