<template>
  <PageHeader />
  <main class="page-content">
    <div class="p-container">
      <div class="content-header">
        <h1
          class="content-header-text flex justify-content-between align-items-end"
        >
          Всі посилання
        </h1>
        <hr class="content-header-divider" />
      </div>
      <div class="grid">
        <div
          class="col-12 md:col-6 lg:col-4"
          v-for="link in importantLinksData?.rows"
          :key="link.id"
        >
          <ImportantLinkCard :link="link" />
        </div>
      </div>
    </div>
    <PagePaginator
      class="mt-6"
      :count="importantLinksData?.count"
      :page="importantLinksData.pages"
      :totalRows="importantLinksData?.total_rows"
      :pageSize="filters.pageSize"
      @getItemsByPage="
        async (page) => {
          filters.page = page;

          await getNews();
        }
      "
    />
  </main>
  <PageFooter />
</template>

<script>
import PageHeader from "@/components/public/page/PageHeader.vue";
import PageFooter from "@/components/public/page/PageFooter.vue";
import PagePaginator from "@/components/public/page/PagePaginator.vue";
import ImportantLinkCard from "@/components/public/content/ImportantLinkCard.vue";

import types from "@/config/types.yml";
import filters from "@/config/filters.yml";

import FiltersMixin from "@/mixins/filters";

import { mapActions, mapState } from "vuex";

export default {
  name: "AllImportantLinksView",
  components: {
    PageHeader,
    PageFooter,
    PagePaginator,
    ImportantLinkCard,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      filterPostsByType: {
        field: filters.posts.filterByTypeID,
        value: types.post.link,
      },
      filterPostsIsPubliched: {
        field: filters.posts.filterByPublished,
        value: 1,
      },
      sortLinksFilter: {
        field: filters.posts.sortByPosition,
        sort: "ASC",
      },
      filters: {
        page: 1,
        pageSize: 12,
      },
    };
  },
  computed: {
    ...mapState({
      importantLinksData: (state) => state.public_posts.importantLinksData,
    }),
  },
  methods: {
    ...mapActions(["getPublicPostsData"]),

    async getLinks() {
      try {
        this.filters = this.createFilters(this.filters, [
          this.filterPostsByType,
          this.filterPostsIsPubliched,
          this.sortLinksFilter,
        ]);

        await this.getPublicPostsData({
          filters: this.filters,
          postType: types.post.link,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    try {
      await this.getLinks();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
