import helpers from "@/helpers";

export default (adminURL, instance) => {
  return {
    getUsersData(payload) {
      return instance
        .post(adminURL + "user/all", payload)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
    createUser(payload) {
      return instance
        .post(adminURL + "user/create", payload)
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
    updateUser(id, payload) {
      return instance
        .put(adminURL + `user/${id}`, payload)
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
    removeUser(id) {
      return instance
        .delete(adminURL + `user/${id}`)
        .then(() => helpers.notify.createUserNotify("success"))
        .catch((error) => {
          helpers.notify.createErrorNotify(error);

          throw error;
        });
    },
    getUserTypes() {
      return instance.get(adminURL + "user/types").catch((error) => {
        helpers.notify.createErrorNotify(error);

        throw error;
      });
    },
  };
};
