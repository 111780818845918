<template>
  <div
    :class="[
      'flex filter-field',
      showOptions ? 'justify-content-start' : 'justify-content-center',
    ]"
  >
    <Dropdown
      v-if="showOptions && Array.isArray(filterItems)"
      v-model="filter"
      optionValue="id"
      class="w-full"
      :editable="editable"
      :optionLabel="labelOption"
      :placeholder="placeholder"
      :options="filterItems"
      @change="$emit('applyFilters', filter)"
    />
    <div v-else-if="showOptions" class="flex">
      <InputText
        type="text"
        class="filter-search-field"
        v-model="filter"
        :placeholder="placeholder"
        @input="$emit('applyFilters', filter)"
      />
      <Button
        v-show="showSearchBtn"
        class="p-button-text p-button-rounded p-button-secondary"
        icon="pi pi-search"
        v-tooltip.bottom="`Пошук...`"
        @click="$emit('searchInDB')"
      />
    </div>
    <Button
      class="p-button-text p-button-rounded p-button-secondary"
      icon="pi pi-filter-slash"
      v-tooltip.bottom="`Скинути ${showOptions ? '' : 'всі '}фільтри`"
      @click="
        () => {
          $emit('clearFilters');
          filter = null;
        }
      "
    />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

export default {
  components: {
    Dropdown,
    Button,
    InputText,
  },
  data() {
    return {
      filter: null,
    };
  },
  props: {
    editable: Boolean,
    labelOption: String,
    filterItems: [Array, String],
    placeholder: String,
    filterType: String,
    filtersName: String,
    filterField: String,
    showOptions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showSearchBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  emits: ["clearFilters", "applyFilters", "searchInDB"],
  methods: {
    getFiltersInStorage() {
      if (this.filtersName && localStorage.getItem(this.filtersName)) {
        let filters = JSON.parse(localStorage.getItem(this.filtersName))[
          this.$route.meta.postType
        ];
        if (filters && filters.filtered && filters.filtered.length > 0) {
          let filterItem = filters.filtered.find(
            (el) => el.field === this.filterField
          );

          this.filter = filterItem ? filterItem.value : null;
        }
      }
    },
  },
  mounted() {
    this.getFiltersInStorage();
  },
};
</script>
