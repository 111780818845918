<template>
  <BreadcrumbsList :breadcrumbs="breadcrumbs" />

  <div class="content mt-3">
    <UsersTable
      title="Користувачі"
      :users="usersData.rows"
      @getItemsByFilter="
        (filter) => {
          filters = createFilters(filters, [filter]);
          getUsers();
        }
      "
      @clearFilters="
        () => {
          filters = clearFilters();
          getUsers();
        }
      "
    />
  </div>
  <div class="content mt-3 p-2">
    <TablePaginator
      :rows="usersData.count"
      :totalRows="usersData.total_rows"
      @getItemsByPage="
        (event) => {
          changePage(filters, event);
          getUsers();
        }
      "
    />
  </div>
</template>

<script>
import UsersTable from "@/components/administration/users/UsersTable.vue";
import BreadcrumbsList from "@/components/administration/ui/BreadcrumbsList.vue";
import TablePaginator from "@/components/administration/ui/TablePaginator.vue";
import { mapActions, mapState } from "vuex";

import FiltersMixin from "@/mixins/filters";

export default {
  name: "UsersView",
  components: {
    UsersTable,
    BreadcrumbsList,
    TablePaginator,
  },
  mixins: [FiltersMixin],
  data() {
    return {
      breadcrumbs: [{ label: "Користувачі" }],
      search: "",
      filters: { page: 1, pageSize: 20 },
    };
  },
  computed: {
    ...mapState({
      usersData: (state) => state.users.usersData,
    }),

    managers() {
      if (this.usersData.rows) {
        let managerUsers = this.usersData.rows.filter((item) => {
          let fullname = item.lastname + item.firstname + item.surname;
          return fullname.toLowerCase().indexOf(this.search) > -1;
        });

        return managerUsers;
      }

      return [];
    },
  },
  methods: {
    ...mapActions([
      "getUsersData",
      "clearUsersData",
      "getSectionsData",
      "getUserTypes",
    ]),

    async getUsers() {
      await this.getUsersData(this.createFilters(this.filters));
    },
  },
  async mounted() {
    try {
      await this.getUserTypes();
      await this.getUsers();

      await this.getSectionsData({
        page: 1,
        pageSize: -1,
        filtered: [],
      });
    } catch (e) {
      console.log(e);
    }
  },
  unmounted() {
    this.clearUsersData();
  },
};
</script>
