<template>
  <div class="latest-news">
    <a href="https://forms.gle/aSH77pBQEWZ8ntEt5" class="poll poll-card">
      <div class="y"></div>
      <div class="b"></div>
      <div class="b2"></div>
      <div class="db"></div>
      <div class="poll-card-body">
        <h1><span>Опитування</span></h1>
        <span class="description">
          Портрет та потреби внутрішньо переміщених осіб на території Черкащини
        </span>
      </div>
    </a>
    <LatestNewsCard v-for="news in latestNews" :key="news.id" :news="news" />
    <router-link class="all-news-link" to="/news"> Всі новини </router-link>
  </div>
</template>

<script>
import LatestNewsCard from "./LatestNewsCard.vue";

export default {
  components: {
    LatestNewsCard,
  },
  props: {
    latestNews: Array,
  },
};
</script>
