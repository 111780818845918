<template>
  <div class="page-breadcrumbs">
    <div class="p-container">
      <Breadcrumb :home="home" :model="items">
        <template #item="data">
          <a v-if="data.item.to" :href="data.item.to">{{ data.item.label }}</a>
          <span v-else>{{ data.item.label }}</span>
        </template>
      </Breadcrumb>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      home: {
        label: "Головна",
        to: "/",
      },
    };
  },
};
</script>
