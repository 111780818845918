<template>
  <DataTable
    stripedRows
    responsiveLayout="scroll"
    filterDisplay="row"
    class="p-datatable-sm"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Показано {first} з {last} із {totalRecords}"
    :rowHover="true"
    :value="users"
  >
    <template #empty> Дані відсутні </template>
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <span class="text-xl text-900 font-bold">{{ title }}</span>
        <Button
          rounded
          raised
          icon="pi pi-plus"
          v-tooltip.left="'Додати нового користувача'"
          @click="createUser()"
        />
      </div>
    </template>
    <Column header="П.І.Б" headerStyle="width: 20%">
      <template #body="slotProps">
        <div>
          <span>
            {{
              `${slotProps.data.surname} ${slotProps.data.firstname} ${slotProps.data.lastname}`
            }}
          </span>
        </div>
      </template>
    </Column>
    <Column
      field="is_active"
      headerStyle="width: 10%"
      :showFilterMenu="false"
      :showClearButton="false"
      :filtersName="'user_filters'"
    >
      <template #header>
        <div class="text-center w-full">
          <span>Статус</span>
        </div>
      </template>
      <template #filter>
        <ColumnFilterPanel
          :placeholder="'Усі'"
          :filterItems="userStatuses"
          :labelOption="'name'"
          :key="filterKey"
          @applyFilters="
            (val) => {
              getItemsByFilters(filterUsersByStatus, val);
            }
          "
          @clearFilters="
            () => {
              getItemsByFilters(filterUsersByStatus, null);
            }
          "
        />
      </template>
      <template #body="slotProps">
        <div class="text-center">
          <Tag
            :severity="!!slotProps.data.is_active ? 'success' : 'warning'"
            :value="!!slotProps.data.is_active ? 'Активний' : 'Відключений'"
          />
        </div>
      </template>
    </Column>
    <Column
      headerStyle="width: 15%"
      :showFilterMenu="false"
      :showClearButton="false"
    >
      <template #filter>
        <ColumnFilterPanel
          :showOptions="false"
          @clearFilters="
            () => {
              filterKey++;

              $emit('clearFilters');
            }
          "
        />
      </template>
      <template #body="slotProps">
        <TableItemControls
          :showViewBtn="false"
          :itemName="`${slotProps.data.surname} ${slotProps.data.firstname} ${slotProps.data.lastname}`"
          @editFunc="editUser(slotProps.data)"
          @removeFunc="removeUser(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>
  <DynamicDialog />
</template>

<script>
import TableItemControls from "../ui/TableItemControls.vue";
import ColumnFilterPanel from "../ui/ColumnFilterPanel.vue";
import UserForm from "./UserForm.vue";
import DynamicDialog from "primevue/dynamicdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Tag from "primevue/tag";

import filters from "@/config/filters.yml";

import DialogMixin from "@/mixins/dialog";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    TableItemControls,
    ColumnFilterPanel,
    DynamicDialog,
    DataTable,
    Column,
    Button,
    Tag,
  },
  mixins: [DialogMixin],
  props: {
    users: Array,
    title: String,
  },
  emits: ["getItemsByFilter", "clearFilters"],
  data() {
    return {
      filterKey: 0,
      filterUsersByTypeID: {
        field: filters.users.filterByTypeID,
        value: null,
      },
      filterUsersByStatus: {
        field: filters.users.filterByStatus,
        value: null,
      },
      userStatuses: filters.users.statuses,
    };
  },
  computed: {
    ...mapState({
      userTypes: (state) => state.users.userTypes,
      loader: (state) => state.loader,
    }),
  },
  methods: {
    ...mapActions(["removeUserData"]),

    createUser() {
      this.createDialog(UserForm, {
        props: {
          header: "Новий користувач",
          modal: true,
        },
        data: {
          user: null,
        },
      });
    },
    editUser(user) {
      this.createDialog(UserForm, {
        props: {
          header: "Редагувати користувача",
          modal: true,
        },
        data: {
          user: user,
        },
      });
    },
    async removeUser(id) {
      await this.removeUserData(id);
    },
    getItemsByFilters(filter, value) {
      filter.value = value;

      this.$emit("getItemsByFilter", filter);
    },
  },
};
</script>
