<template>
  <div
    class="h-screen h-full auth-wrapper flex justify-content-center align-items-center p-fluid"
  >
    <div class="back-auth-form content text-center">
      <h2>{{ section?.title ? section.title : "Панель керування" }}</h2>

      <AuthForm />
    </div>
  </div>
</template>

<script>
import AuthForm from "@/components/administration/auth/AuthForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "ManagerAuthView",
  components: {
    AuthForm,
  },
  computed: {
    ...mapGetters(["isAdmin", "adminTokenIsValid"]),
  },
  mounted() {
    if (this.adminTokenIsValid && this.$route.meta.isAdmin && this.isAdmin) {
      this.$router.push({ name: "admin-news" });
    }
  },
};
</script>
