<template>
  <div class="mt-3">
    <form class="py-2" @submit.prevent="saveUser(user)">
      <div class="p-fluid grid mb-4">
        <div class="col-12 md:col-4">
          <span class="p-float-label">
            <InputText
              required
              id="surname"
              type="text"
              v-model="user.surname"
              :class="user.surname ? '' : 'p-invalid'"
            />
            <label for="surname">Прізвище</label>
          </span>
        </div>
        <div class="col-12 md:col-4">
          <span class="p-float-label">
            <InputText
              required
              id="firstname"
              type="text"
              v-model="user.firstname"
              :class="user.firstname ? '' : 'p-invalid'"
            />
            <label for="firstname">Ім'я</label>
          </span>
        </div>
        <div class="col-12 md:col-4">
          <span class="p-float-label">
            <InputText id="lastname" type="text" v-model="user.lastname" />
            <label for="lastname">По батькові</label>
          </span>
        </div>
      </div>
      <div class="p-fluid grid mb-4">
        <div class="col-12 md:col-4">
          <span class="p-float-label">
            <InputText
              required
              id="login"
              type="text"
              v-model="user.login"
              :class="user.login ? '' : 'p-invalid'"
            />
            <label for="login">Логін</label>
          </span>
        </div>
        <div class="col-12 md:col-4 pb-0">
          <span class="p-float-label">
            <InputText id="password" type="text" v-model="user.password" />
            <label for="password">Пароль</label>
          </span>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <ToggleButton
            v-model="isActive"
            onLabel="Користувач активний"
            offLabel="Користувач неактивний"
          />
        </div>
        <div class="col-12 flex justify-content-end">
          <Button
            type="submit"
            label="Зберегти"
            v-tooltip.bottom="'Зберегти'"
            :disabled="user.id_section === null"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import ToggleButton from "primevue/togglebutton";
import Button from "primevue/button";

import { mapActions, mapState } from "vuex";

export default {
  inject: ["dialogRef"],
  components: {
    InputText,
    ToggleButton,
    Button,
  },
  data() {
    return {
      user: {
        id: null,
        id_type: 1,
        login: null,
        password: null,
        surname: null,
        firstname: null,
        is_active: null,
      },
      isActive: false,
    };
  },
  watch: {
    isActive(newValue) {
      this.user.is_active = newValue ? 1 : 0;
    },
  },
  computed: {
    ...mapState({
      sectionsData: (state) => state.sections.sectionsData,
      userTypes: (state) => state.users.userTypes,
    }),
  },
  methods: {
    ...mapActions(["createUserData", "updateUserData"]),

    saveUser(user) {
      user.is_active = this.isActive ? 1 : 0;

      try {
        if (user.id) {
          this.updateUserData({
            id: user.id,
            user_data: user,
          });
        } else {
          this.createUserData(user);
        }

        this.dialogRef.close();
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    if (this.dialogRef.data.user) {
      this.user = { ...this.dialogRef.data.user };

      this.isActive = !!this.user.is_active;
      this.user.password = "";
    }
  },
};
</script>
