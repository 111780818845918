import helpers from "@/helpers";

export default function (instance) {
  return {
    getItemsBySearchQuery(payload) {
      return instance
        .post("api/search", payload)
        .catch((error) => helpers.notify.createErrorNotify(error));
    },
  };
}
